import PropTypes from "prop-types"
import React from "react"
import FadeIn from "react-fade-in"
import AnchorLink from "react-anchor-link-smooth-scroll"

import logo from "../../static/images/logo.svg"
import typeface from "../../static/images/typeface.svg"
import hero from "../../static/images/header-bg-1.svg"

import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Button from "react-bootstrap/Button"

const Header = ({ siteTitle }) => (
  <header>
    <Container id="home" fluid>
      <FadeIn>
        <Navbar collapseOnSelect expand="xl">
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="56"
              height="56"
              className="d-inline-block align-top"
              alt="Lotus Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-content-end row no-gutters nav-text">
              <AnchorLink href="#home" className="nav-link">
                HOME
              </AnchorLink>
              <AnchorLink href="#portfolio" className="nav-link">
                PORTFOLIO
              </AnchorLink>
              <AnchorLink href="#contact" className="nav-link">
                CONTACT
              </AnchorLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </FadeIn>
    </Container>
    <FadeIn>
      <Container className="text-center">
        <img src={typeface} className="header-typeface" alt="Typeface" />
      </Container>
      <Container className="text-center">
        <h3 className="header-text">Premium Website Design and Development.</h3>
        <h3 className="header-text">
          Clean, functional and intuitive websites for brands and individuals.
        </h3>
      </Container>
      <Container className="text-center">
        <AnchorLink href="#about">
          <Button className="header-button">LEARN MORE</Button>
        </AnchorLink>
      </Container>
      <Container className="text-center">
        <img src={hero} className="header-image" alt="header-hero" />
      </Container>
    </FadeIn>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Portfolio`,
}

export default Header
